import { Injectable } from '@angular/core';
import { UserManager, UserManagerSettings, User, WebStorageStateStore } from 'oidc-client';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private manager = new UserManager(this.getClientSettings());
  private user: User | null;

  constructor(private localStorageService: LocalStorageService) {
    const storedUser = this.localStorageService.load(LocalStorageService.AUTH_CURRENT_USER);

    if (storedUser) {
      this.user = storedUser;
    }

    this.manager.getUser().then((user) => {
      this.user = user;
      this.localStorageService.store(LocalStorageService.AUTH_CURRENT_USER, this.user);
    });

    this.manager.events.addUserLoaded((args) => {
      this.manager.getUser().then((user) => {
        this.user = user;
        this.localStorageService.store(LocalStorageService.AUTH_CURRENT_USER, this.user);
      });
    });
  }

  login() {
    return this.manager.signinRedirect();
  }

  logout() {
    this.localStorageService.remove(LocalStorageService.AUTH_CURRENT_USER);
    this.user = null;
    return this.manager.signoutRedirect();
  }

  getUserName(): string {
    return this.user != null ? this.user.profile['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'] : '';
  }

  getAuthorizationHeaderValue(): string {
    if (!this.user) {
      return null;
    }
    return `${this.user.token_type} ${this.user.id_token}`;
  }

  async completeAuthentication() {
    this.user = await this.manager.signinRedirectCallback();
    if (this.user) {
      this.localStorageService.store(LocalStorageService.AUTH_CURRENT_USER, this.user);
    }
    await this.manager.clearStaleState();
  }

  async completeSilentRenewal() {
    await this.manager.signinSilentCallback();
    await this.manager.clearStaleState();
  }

  isAuthenticated(): boolean {
    const expiryDateInMiliseconds = this.user?.expires_at * 1000;
    return !isNaN(expiryDateInMiliseconds) && expiryDateInMiliseconds > Date.now();
  }

  storeUnaturizeRoute(route: string) {
    this.localStorageService.store(LocalStorageService.LAST_UNAUTHETNICATED_ROUTE, route);
  }

  private getClientSettings(): UserManagerSettings {
    return {
      authority: environment.ssoUrl,
      client_id: 'customerportal.web',
      redirect_uri: environment.localUrl + '/auth/auth-callback',
      response_type: 'id_token token',
      scope: 'openid profile custom.userinfo nexus.api.access',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      staleStateAge: 300,
      automaticSilentRenew: true,
      silent_redirect_uri: environment.localUrl + '/auth/silent-renew-callback',
    };
  }
}
