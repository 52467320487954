<ul [class.slim]="!expand">
  <li *ngFor="let menuItem of menu; let i = index" [class]="menuItem.class">
    <div class="menu-item" routerLinkActive="active">
      <a *ngIf="menuItem?.children" class="block" (click)="collapse(i)">
        <i class="icon" [ngClass]="menuItem.icon" *ngIf="menuItem.icon"></i>
        <img *ngIf="menuItem.iconSrc" [src]="menuItem.iconSrc" />
        <div class="label">{{ menuItem.label }}</div>
        <i class="fa fa-angle-down onEnd" [class.rotate]="collapsedList[i]" aria-hidden="true"></i>
      </a>

      <a
        *ngIf="!menuItem?.children"
        class="block"
        [routerLink]="menuItem.routerLink"
        routerLinkActive="active"
        (click)="collapse(i)"
        [pTooltip]="menuItem.label"
        tooltipPosition="right"
        showDelay="150"
        [tooltipDisabled]="expand"
      >
        <i class="icon" [ngClass]="menuItem.icon" *ngIf="menuItem.icon"></i>
        <img *ngIf="menuItem.iconSrc" [src]="menuItem.iconSrc" />
        <div class="label">{{ menuItem.label }}</div>
      </a>

      <div *ngIf="!expand && menuItem?.children" class="tooltip">
        <div class="menu-parent-title">{{ menuItem.label }}</div>
        <ul class="content">
          <li
            *ngFor="let childMenuItem of menuItem?.children; let ic = index"
            [class.extra-margin-bottom]="childMenuItem.extraMarginMottom"
          >
            <a class="block" [routerLink]="childMenuItem.routerLink" routerLinkActive="active">
              <i class="icon" [ngClass]="childMenuItem.icon" *ngIf="childMenuItem.icon"></i>
              <img *ngIf="childMenuItem.iconSrc" [src]="childMenuItem.iconSrc" />
              <div class="label">{{ childMenuItem.label }}</div>
            </a>
          </li>
        </ul>
      </div>
      <ul class="content" *ngIf="menuItem?.children" [class.hidden]="(!collapsedList[i] || !expand) && menuItem?.children">
        <li *ngFor="let childMenuItem of menuItem?.children; let ic = index" [class.extra-margin-bottom]="childMenuItem.extraMarginMottom">
          <a class="block" [routerLink]="childMenuItem.routerLink" routerLinkActive="active">
            <i class="icon" [ngClass]="childMenuItem.icon" *ngIf="childMenuItem.icon"></i>
            <img *ngIf="childMenuItem.iconSrc" [src]="childMenuItem.iconSrc" />
            <div class="label">{{ childMenuItem.label }}</div>
          </a>
        </li>
      </ul>
    </div>
  </li>

  <li class="logout">
    <div class="menu-item">
      <div class="block" (click)="logout()" pTooltip="Logout" tooltipPosition="right" showDelay="150" [tooltipDisabled]="expand">
        <i class="fa fa-power-off icon"></i>
        <div class="label">Logout</div>
      </div>
    </div>
  </li>
</ul>

<div class="expand block" (click)="onExpandMenu()" pTooltip="Expand Menu" tooltipPosition="right" showDelay="150">
  <i class="fa fa-angle-double-right icon" aria-hidden="true" [class.rotate]="expand"></i>
</div>
