import { AuthService } from './../../../services/auth.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss'],
})
export class LeftMenuComponent implements OnInit {
  collapsedList: Array<boolean> = [false, false, false, false, false, false, false];
  lastIndex = null;

  @Input() expand: boolean;

  @Output()
  expandEvent = new EventEmitter<boolean>();

  menu: { label: string; routerLink: string; icon?: string; iconSrc?: string; children?: any[]; class?: string }[] = [
    { label: 'History', routerLink: '/history', iconSrc: this.iconsSrcPath('history') },
    { label: 'Invoices', routerLink: '/history/invoices', iconSrc: this.iconsSrcPath('pricings') },
  ];

  constructor(private authService: AuthService) {}

  iconsSrcPath(name: string) {
    return `../../../../../assets/icons/menu/${name}.svg`;
  }

  ngOnInit() {}

  collapse(index: number, collapseParent: boolean = true) {
    this.collapsedList.forEach((x, i) => {
      if (i !== index && collapseParent) {
        this.collapsedList[i] = false;
      }
    });
    this.collapsedList[index] = !this.collapsedList[index];
    this.lastIndex = index;
  }

  onHideMenu() {
    this.expand = false;
    this.expandEvent.emit(this.expand);
  }

  onExpandMenu() {
    this.collapsedList.forEach((x, i) => {
      this.collapsedList[i] = false;
    });
    this.expand = !this.expand;
    this.expandEvent.emit(this.expand);
  }

  logout() {
    this.authService.logout();
  }
}
