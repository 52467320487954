import { MissingTranslationHandler, MissingTranslationHandlerParams, TranslateService } from '@ngx-translate/core';
import { Injectable, Injector } from '@angular/core';
import { MaintenanceService } from '../services/maintenance.service';

@Injectable()
export class NexusMissingTranslationHandler implements MissingTranslationHandler {
  constructor(private injector: Injector) {}

  handle(params: MissingTranslationHandlerParams) {
    let message = 'Translations Undefined';
    let stackTrace = '';

    if (params.translateService.translations && Object.keys(params.translateService.translations).length !== 0) {
      stackTrace = JSON.stringify(params.translateService.translations, null, '\t');
      message = 'Current Language Undefined';

      if (params.translateService.currentLang) {
        message = `Missing Web translation for key: '${params.key}', current language: '${params.translateService.currentLang}'`;
      }

      this.injector
        .get(MaintenanceService)
        .saveWebMissibngTranslationLog(message, stackTrace)
        .subscribe((x) => {});
    }

    return params.key;
  }
}
